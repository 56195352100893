// Imports
// ------
import React, { useEffect, useState } from 'react';
import SingleNFT from './SingleNFT';
import Button from '@parts/Button/Submit';
import { Row, Column } from '@waffl';

// Styles
// ------
import { Jacket, List, LoadMore } from './styles';

// Component
// ------
const NftListing = ({ items }) => {
	const [visible, setVisible] = useState(9);
	const [allNFTs, setAllNFTs] = useState([]);

	const loadMore = () => {
		setVisible(visible + 6);
	};

	useEffect(() => {
		setAllNFTs(items);
	}, [items]);

	return (
		<Jacket padBottom>
			<Row isExpanded sidePad className='row'>
				<Column small={12} mpad>
					<List>
						{allNFTs.slice(0, visible).map((block) => (
							<SingleNFT
								key={block.node.title}
								sub={block.node.sub}
								main={block.node.title}
								thumb={block.node.thumbnailImage}
								image={block.node.featuredImage}
								editions={block.node.editions}
								number={block.node.number}
								year={block.node.year}
								code={block.node.qr}
								link={block.node.link}
							/>
						))}
					</List>

					{visible < allNFTs.length && (
						<LoadMore>
							<Button onClick={loadMore} label='Load more' noIcon />
						</LoadMore>
					)}
				</Column>
			</Row>
		</Jacket>
	);
};

export default NftListing;
