// Imports
// ------
import React from 'react';
import Tilt from 'react-tilt';
import { GatsbyImage } from 'gatsby-plugin-image';

// Styles
// ------
import {
	Jacket,
	Content,
	SubTitle,
	Title,
	Image,
	QRCode,
	Detail,
} from './styles';

// Component
// ------
const SingleNFT = ({
	sub,
	main,
	thumb,
	editions,
	number,
	year,
	code,
	link,
}) => {
	const options = {
		scale: 0.95,
		max: 15,
		reverse: true,
	};

	return (
		<Jacket to={link} entry={{ length: 1 }} exit={{ length: 1 }}>
			<Tilt className='tilt' options={options}>
				<SubTitle>{sub}</SubTitle>

				<Image>
					<GatsbyImage
						image={thumb.gatsbyImageData}
						src={thumb.gatsbyImageData.placeholder.fallback}
						alt={thumb.alt ? thumb.alt : 'NFT Featured Image'}
					/>
				</Image>

				<Content>
					<Title>{main}</Title>

					<Detail weight='bold'>
						NFT: <span>{number}</span>
					</Detail>

					<Detail weight='bold'>
						Editions: <span>{editions}</span>
					</Detail>

					<Detail weight='bold' year>
						Year: <span>{year}</span>
					</Detail>

					<QRCode>
						<GatsbyImage
							image={code.gatsbyImageData}
							alt={code.alt ? code.alt : 'QR Code'}
						/>
					</QRCode>
				</Content>
			</Tilt>
		</Jacket>
	);
};

export default SingleNFT;
