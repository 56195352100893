// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, grad, Section } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		background: ${props.theme.black};

		&:before {
			/* ${grad} */
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			height: 24rem;
		}

		.row {
			z-index: 2;
		}
	`
);

export const List = styled.ul(
	(props) => css`
		display: grid;
		grid-gap: 2.4rem;
		grid-auto-rows: 1fr;
		grid-template-columns: 1fr;

		${breakup.medium`
			grid-template-columns: repeat(2, 1fr);
		`}

		${breakup.large`
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 3.6rem;
		`}

		${breakup.xlarge`
			grid-gap: 4.8rem;
		`}

		${breakup.xxlarge`
			grid-gap: 6rem;
		`}
	`
);

export const LoadMore = styled.div(
	(props) => css`
		margin: 6rem 0 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
	`
);
