// Imports
// ------
import React, { useEffect, useState } from 'react';
import { Row, Column } from '@waffl';

// Styles
// ------
import { Jacket, Title } from './styles';

// Component
// ------
function Hero({ title }) {
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoaded(true);
		}, 750);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<Jacket pad isLoaded={isLoaded}>
			<Row isExpanded sidePad>
				<Column mpad>
					<Title>{title}</Title>
				</Column>
			</Row>
		</Jacket>
	);
}

export default Hero;
