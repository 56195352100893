// Imports
// ------
import React, { useEffect } from 'react';
import Seo from '@lay/Seo';
import PageTransition from '@parts/PageTransition';
import Hero from '@parts/Hero/NFT';
import NftListing from '@parts/NftListing';
import Newsletter from '@parts/Newsletter';
import Footer from '@parts/Footer';
import { graphql } from 'gatsby';
import { headerState } from '@states/header';
import { action } from 'mobx';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Component
// ------
function Projects({ transitionStatus, entry, exit, data }) {
	const ts = transitionStatus;
	const bp = useBreakpoint();

	useEffect(() => {
		const hideNotification = action(() => {
			headerState.notifyActive = false;
			headerState.notifySize = 0;
		});

		hideNotification();

		const timer = setTimeout(
			() => {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			},
			bp.large ? 500 : 1000
		);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	// If NFTs arent enabled
	useEffect(() => {
		if (data.pageEnabled.nftsEnabled === false) {
			window.history.go(-1);
		}
	}, []);

	if (data.pageEnabled.nftsEnabled) {
		return (
			<>
				<Seo data={data.page.seo} backupData={data.globalSeo} />

				<PageTransition status={ts} entry={entry} exit={exit}>
					<Hero title={data.page.title} />
					<NftListing items={data.posts.edges} />
					<Newsletter />
					<Footer />
				</PageTransition>
			</>
		);
	} else return null;
}

export default Projects;

// GraphQL
// ------
export const query = graphql`
	query NftListing {
		globalSeo: datoCmsOptionsGlobal {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
		}

		pageEnabled: datoCmsOptionsGlobal {
			nftsEnabled
		}

		page: datoCmsNftDropsListing {
			title
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
		}

		posts: allDatoCmsNftDropsPost {
			edges {
				node {
					link: slug
					sub: subTitle
					title
					thumbnailImage {
						gatsbyImageData(
							width: 350
							placeholder: BLURRED
							forceBlurhash: false
							layout: FULL_WIDTH
							backgroundColor: ""
						)
						alt
						smartTags
					}
					featuredImage {
						gatsbyImageData(
							width: 350
							placeholder: BLURRED
							forceBlurhash: false
							layout: FULL_WIDTH
							backgroundColor: ""
						)
						alt
						smartTags
					}
					number: nftNumber
					editions
					year
					qr: qrCode {
						gatsbyImageData(
							width: 72
							placeholder: BLURRED
							forceBlurhash: false
							layout: FULL_WIDTH
							backgroundColor: ""
						)
						alt
						smartTags
					}
				}
			}
		}
	}
`;
