// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, grad, H1, Section } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		background: linear-gradient(-90deg, #303339 -100%, #080809 50%);

		&:before {
			${grad}
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			height: calc(100% + 24rem);

			transform: ${props.isLoaded ? `scaleY(1);` : `scaleY(0);`};
			transform-origin: top center;
			transition: 1.25s ${props.theme.bezzy};
			transition-delay: 0.25s;
		}
	`
);

export const Title = styled(H1)(
	(props) => css`
		position: relative;
		z-index: 2;
		text-align: center;
		margin-top: ${props.theme.marS};

		${breakup.medium`
            margin-top: ${props.theme.marM};
        `}

		${breakup.large`
            margin-top: ${props.theme.marL};
        `}
	`
);
